import parameters from '../../api/parameters'

const state = () => ({
  all: {},
  config: {},
  loading: false,
  currentDevice: undefined,
  // listener: undefined,
  editDialog: false,
  editDialogParam: undefined,
  leftCounters: {},
  endTime: {},
  updateTime: undefined,
  inputTime: undefined // time of last parameter change by user
})

const getters = {
  currentDevice(state) {
    return state.currentDevice
  },
  all(state) {
    return state.all
  },
  parameters(state) {
    return state.all
  },
  config(state) {
    return state.config
  },
  param: (state) => (id) => {
    return state.all[id] || {}
  },
  paramValue: (state) => (id) => {
    return (state.all[id]) ? state.all[id].value : null
  },
  paramConfig: (state) => (id) => {
    return state.config[id] || {}
  },
  loading(state) {
    return state.loading
  },
  editDialog(state) {
    return state.editDialog
  },
  editDialogParam(state) {
    return state.editDialogParam
  },
  updateTime(state) {
    return state.updateTime
  },
  inputTime(state) {
    return state.inputTime
  }
}

const mutations = {
  setCurrentDevice: (state, val) => {
    if (state.currentDevice !== val) {
      state.currentDevice = val
      state.endTime = {}
    }
  },
  setAll: (state, data) => {
    state.all = data
  },
  setParameter: (state, {name, value}) => {
    state.all[name].value = value
  },
  setConfig: (state, data) => {
    state.config = data
  },
  setLoading: (state, data) => {
    state.loading = data
  },
  setListener: (state, interval) => {
    state.listener = interval
  },
  editDialogOpen: (state, param) => {
    state.editDialogParam = param;
    state.editDialog = true;
  },
  editDialogClose: (state) => {
    state.editDialog = false
  },
  setUpdateTime: (state) => {
    state.updateTime = Date.now()
  },
  setInputTime: (state) => {
    state.inputTime = Date.now()
  }
}

const actions = {
  setCurrentDevice({commit}, controllerId) {
    commit('setCurrentDevice', controllerId);
  },
  getAll({state, commit}) {
    //commit('setLoading', true, { root: true });
    return parameters.getAll(state.currentDevice)
    .then(data => {
      if (data && !data.error) {
        commit('setAll', data);
        commit('setUpdateTime');
      }
      //commit('setLoading', false, { root: true });
      return data;
    })
  },
  getConfig({state, commit}) {
    //commit('setLoading', true, { root: true });
    return parameters.getConfig(state.currentDevice)
    .then(data => {
      if (!data.error) { 
        commit('setConfig', data); 
      }
      //commit('setLoading', false, { root: true });
      return data;
    })
  },
  /*startListener({commit, state, dispatch}) {
    if (state.listener === undefined) {
      commit('setListener', setInterval(() => {
        dispatch('getAll')
      }, 60000))
    }
  },*/
  update({state, commit, dispatch}, {paramName, paramValue}) {
    commit('setLoading', true);
    return parameters.update(state.currentDevice, paramName, paramValue)
    .then(data => {
      if (!data.error) { 
        // pobierz po zapisie
        dispatch('getAll'); 
        commit('setInputTime');
      } 
      commit('setLoading', false);
      return data;
    })
  },
  updateTemporary({state, commit}, {paramName, paramValue, time, params}) {
    commit('setLoading', true);
    return parameters.updateTemporary(state.currentDevice, paramName, paramValue, time, params)
    .then(data => {
      commit('setLoading', false);
      return data;
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}