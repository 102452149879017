import Vue from 'vue'
import Vuex from 'vuex'
import { loadApiUrl } from "../api/api";
import profile from './modules/profile'
import locales from './modules/locales'
import controllers from './modules/controllers'
import parameters from './modules/parameters'
import schedules from './modules/schedules'
import menu from './modules/menu'
import snack from './modules/snack'
import users from './modules/users'
import alarms from './modules/alarms'
import statistics from './modules/statistics'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pageTitle: undefined,
    loading: false,
    isMobile: true,
    clock: undefined, // 1 min. clock api sync
    clockInterval: undefined,
    second: undefined, // 1 sec. clock
    secondInterval: undefined
  },
  mutations: {
    setPageTitle: (state, val) => {
      state.pageTitle = val
    },
    setLoading: (state, data) => {
      state.loading = data
    },
    setIsMobile: (state, val) => {
      state.isMobile = val
    },
    setClock: (state, val) => {
      state.clock = val
    },
    setSecond: (state, val) => {
      state.second = val
    }
  },
  getters: {
    pageTitle(state) {
      return state.pageTitle
    },
    loading(state) {
      return state.loading
    },
    isMobile(state) {
      return state.isMobile
    },
    clock(state) {
      return state.clock
    },
    second(state) {
      return state.second
    }
  },
  actions: {
    updateApiUrl() {
      return loadApiUrl()
    },
    startClock({commit, state}) {
      if (state.clockInterval === undefined) {
        state.clockInterval = setInterval(() => {
          commit('setClock', Date.now())
        }, 60000)
      }
    },
    stopClock(state) {
      state.clockInterval = undefined
    },
    startSecond({commit, state}) {
      if (state.secondInterval === undefined) {
        state.secondInterval = setInterval(() => {
          commit('setSecond', Date.now())
        }, 1000)
      }
    },
    stopSecond(state) {
      state.secondInterval = undefined
    }
  },
  modules: {
    profile,
    locales,
    controllers,
    parameters,
    schedules,
    menu,
    users,
    alarms,
    statistics,
    snack
  }
})
