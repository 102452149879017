<template>
  <v-list-item v-if="isParam" @click.stop="(isEditable || config.setParam) ? ed() : null">
    <v-list-item-content>{{ config.label }}</v-list-item-content>
    <v-list-item-action class="flex-row" :class="{'c-primary': isEditable }">
      <span v-if="config.options">{{ config.options[paramValue(data.param)] }}</span>
      <span v-else-if="typeof paramValue(data.param) === 'string' || typeof paramValue(data.param) === 'number'">{{ paramValue(data.param) | valueFilter }}</span>
      <v-icon v-else-if="isEditable" color="primary">edit</v-icon>
      <span v-if="config.unit == 'C'">&deg;C</span>
      <span v-else-if="config.unit" v-text="config.unit"></span>
    </v-list-item-action>
  </v-list-item>
  <v-list-item v-else>
    <v-list-item-content>{{ data.label }}</v-list-item-content>
    <v-list-item-action class="flex-row">
      <span v-if="data.value">{{ data.value }}</span>
      <span v-if="data.unit == 'C'">&deg;C</span>
      <span v-else-if="data.unit" v-text="config.unit"></span>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  props: ['data'],
  computed: {
    ...mapGetters('profile', ['locale']),
    ...mapGetters('parameters', ['paramValue', 'paramConfig']),
    isParam() {
      return !!this.data.param
    },
    config() {
      return this.paramConfig(this.data.param)
    },
    setConfig() {
      return (this.config.setParam) ? this.paramConfig(this.config.setParam) : {}
    },
    setterUrl() {
      return (this.data.value_set) ? `/controllers/${this.controllerId}/${this.data.id}` : null
    },
    isEditable() {
      return +this.config.edit === 1
    }
  },
  methods: {
    ...mapMutations('parameters', ['editDialogOpen']),
    ed() {
      if (this.isEditable) {
        this.editDialogOpen(this.data.param);
      } else if (this.config.setParam) {
        this.editDialogOpen(this.config.setParam);
      }
    }
  },
  filters: {
    valueFilter: function (value) {
      if (value == 0) { return 0 }
      else if (!value) return ''
      if (isNaN(value)) {
        return value
      } else {
        return (value.toString().indexOf('.') > -1) ? parseFloat(value).toLocaleString() : value;
      }
    },
  }
}
</script>
