<template>
  <v-card-text class="d-flex flex-column flex-grow-1">
    <div class="py-16 d-flex flex-column flex-grow-1 justify-center">
      <v-list rounded class="nobg">
          <v-list-item>
            <v-list-item-content class="text-center">
              <div v-if="isMobile" class="confirm-ico text--secondary"><svg class="icn" :class="`icn-${config.icon}`"><use :xlink:href="`#icn-${config.icon}`"></use></svg></div>
              <div v-text="value_name" class="confirm-text"></div>
            </v-list-item-content>
          </v-list-item>
      </v-list>
    </div>
    
    <v-card-actions class="flex-shrink-1 pb-5 pb-sm-6 px-3" :class="{'justify-center': isMobile, 'justify-end': !isMobile}">
      <v-btn x-large rounded text exact :color="(isMobile) ? 'primary' : 'secondary'" @click="close" class="mr-1">{{ $t('app.anuluj') }}</v-btn>
      <v-btn x-large rounded :text="!isMobile" color="primary" @click="save" :loading="loading" class="px-6 px-md-6">{{ $t('app[11]') }}</v-btn>
    </v-card-actions>
  </v-card-text>
</template>

<script>
import {mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  props: ['paramName'],
  data: () => ({
		valid: true,
    value_set: undefined,
    value_name: undefined,
    loading: false
	}),
	computed: {
    ...mapGetters('parameters', ['editDialog', 'paramValue', 'paramConfig']),
    ...mapGetters(['isMobile']),
    // ...mapGetters('profile', ['locale']),
    ...mapGetters('locales', ['locales']),
    config() {
      return (this.paramName) ? this.paramConfig(this.paramName) : {}
    }
  },
  watch: {
    paramName() {
      this.setup();
    }
  },
  created() {
    this.setup();
  },
	methods: {
    ...mapMutations('parameters', ['editDialogClose', 'setParameter']),
    ...mapActions('parameters', ['update']),
    setup() {
      for (let key in this.config.confirmation) {
        this.value_set = key;
        this.value_name = this.config.confirmation[key];
      }
    },
    close () {
      this.loading = false;
      this.editDialogClose();
    },
    save () {
      this.loading = true;
      this.update({
        paramName: this.paramName,
        paramValue: this.value_set
      })
      .then(data => {
        if (data.error) {
          this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
          this.loading = false;
        } else {
          if (data[this.paramName]) {
            this.$store.commit('snack/open', { color: "success", timeout: 700, text: this.locales['changes-saved'] });
            //this.setParameter({name: this.paramName, value: this.value_set});
            this.setParameter({name: this.paramName, value: data[this.paramName]});
            this.close();
          } else {
            this.$store.commit('snack/open', { color: "error", timeout: 5000, text: this.locales['no-value'] + ': ' + this.paramName });
            this.loading = false;
          }
        }
      });
    }
	}
}
</script>

<style scoped>
.option-list .listactive.v-list-item--active::before {
  opacity: 0;
}
.confirm-text {
  font-size: 1.25rem;
  line-height: 1.25;
  text-align: center;
}
.confirm-ico {
  font-size: 86px;
}
</style>